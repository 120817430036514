<template>
  <b-overlay :show="loading">

    <b-card
      header="Azioni"
    >
      <b-card-body
        style="padding-bottom: 0"
        class="pl-0"
      >
        <b-button-group>
          <b-button
            variant="primary"
            title="Scarica PDF"
            @click="downloadPdf"
          >
            <feather-icon icon="FileTextIcon" />
          </b-button>
        </b-button-group>
      </b-card-body>
    </b-card>

    <b-card header="Informazioni Generali">
      <b-card-body>

        <b-row>
          <b-col cols="12">
            <cs-input
              id="nopera"
              v-model="model.nopera"
              read-only
              label="N° Opera"
            />
          </b-col>
          <b-col cols="12">
            <cs-input
              id="prog"
              v-model="model.iop"
              read-only
              label="Codice IOP"
            />
          </b-col>
          <b-col cols="12">
            <cs-input
              id="descrizione"
              v-model="model.descrizione"
              read-only
              label="Descrizione"
            />
          </b-col>
          <b-col cols="12">
            <cs-input
              id="prog"
              v-model="model.prog"
              read-only
              label="Prog."
            />
          </b-col>
          <b-col cols="12">
            <cs-input
              id="clas"
              v-model="model.clas"
              read-only
              label="Classificazione AINOP"
            />
          </b-col>
          <b-col cols="12">
            <cs-input
              id="srvizio"
              v-model="model.servizio"
              read-only
              label="Servizio"
            />
          </b-col>
        </b-row>

      </b-card-body>
    </b-card>

    <b-card header="Classe di Attenzione Strutturale e Fondazionale - Pericolosità">
      <b-card-body>

        <b-row>
          <b-col cols="12">
            <cs-input
              id="model.clsfp.classificazione"
              v-model="model.clsfp.classificazione"
              read-only
              label="Classificazione delle strade in funzione della massima massa ammissibile"
            />
          </b-col>
          <b-col cols="12">
            <cs-input
              id="model.clsfp.traffico"
              v-model="model.clsfp.traffico"
              read-only
              label="Frequenza del transito di veicoli commerciali per singola corsia di marcia"
            />
          </b-col>
        </b-row>

      </b-card-body>
    </b-card>
    <b-card header="Classe di Attenzione Strutturale e Fondazionale - Vulnerabilità">
      <b-card-body>

        <b-row>
          <b-col cols="12">
            <cs-input
              id="model.clsfv.lv"
              v-model="model.clsfv.lv"
              read-only
              label="Livello di difettosità attuale"
            />
          </b-col>
          <b-col cols="12">
            <cs-input
              id="model.clsfv.rp"
              v-model="model.clsfv.rp"
              read-only
              label="Rapidità di evoluzione del degrado"
            />
          </b-col>
          <b-col cols="12">
            <cs-input
              id="model.clsfv.norme"
              v-model="model.clsfv.norme"
              read-only
              label="Norma di Progettazione"
            />
          </b-col>
          <b-col cols="12">
            <cs-input
              id="model.clsfv.schema"
              v-model="model.clsfv.schema"
              read-only
              label="Schema statico"
            />
          </b-col>
          <b-col cols="12">
            <cs-input
              id="model.clsfv.materiale"
              v-model="model.clsfv.materiale"
              read-only
              label="Materiale"
            />
          </b-col>
          <b-col cols="12">
            <cs-input
              id="model.clsfv.luce"
              v-model="model.clsfv.luce"
              read-only
              label="Luce"
            />
          </b-col>
          <b-col cols="12">
            <cs-input
              id="model.clsfv.ncampate"
              v-model="model.clsfv.ncampate"
              read-only
              label="Numero campate"
            />
          </b-col>
        </b-row>

      </b-card-body>
    </b-card>
    <b-card header="Classe di Attenzione Strutturale e Fondazionale - Esposizione">
      <b-card-body>

        <b-row>
          <b-col cols="12">
            <cs-input
              id="model.clsfe.lv"
              v-model="model.clsfe.lv"
              read-only
              label="Livello di Traffico Medio Giornaliero sull'intera carreggiata"
            />
          </b-col>
          <b-col cols="12">
            <cs-input
              id="model.clsfe.luce"
              v-model="model.clsfe.luce"
              read-only
              label="Luce media della campata"
            />
          </b-col>
          <b-col cols="12">
            <cs-input
              id="model.clsfe.alternative"
              v-model="model.clsfe.alternative"
              read-only
              label="Alternative stradali"
            />
          </b-col>
          <b-col cols="12">
            <cs-input
              id="model.clsfe.tipo"
              v-model="model.clsfe.tipo"
              read-only
              label="Tipologia di ente scavalcato"
            />
          </b-col>
          <b-col cols="12">
            <cs-input
              id="model.clsfe.trasporto"
              v-model="model.clsfe.trasporto"
              read-only
              label="Trasporto di merci pericolose"
            />
          </b-col>
        </b-row>

      </b-card-body>
    </b-card>

    <b-card header="Classe di Attenzione Sismica - Pericolosità">
      <b-card-body>

        <b-row>
          <b-col cols="12">
            <cs-input
              id="model.clsisp.ag"
              v-model="model.clsisp.ag"
              read-only
              label="ag"
            />
          </b-col>
          <b-col cols="12">
            <cs-input
              id="model.clsisp.topo"
              v-model="model.clsisp.topo"
              read-only
              label="Categoria topografica (Ti)"
            />
          </b-col>
          <b-col cols="12">
            <cs-input
              id="model.clsisp.sotto"
              v-model="model.clsisp.sotto"
              read-only
              label="Categoria del sottosuolo"
            />
          </b-col>
        </b-row>

      </b-card-body>
    </b-card>
    <b-card header="Classe di Attenzione Sismica - Vulnerabilità">
      <b-card-body>

        <b-row>
          <b-col cols="12">
            <cs-input
              id="model.clsisv.schema"
              v-model="model.clsisv.schema"
              read-only
              label="Schema strutturale"
            />
          </b-col>
        </b-row>
        <fieldset class="mb-1 border cs-border p-2">
          <legend class="cs-legend">
            Luce campate
          </legend>
          <b-row>
            <b-col cols="6">
              <cs-input
                id="model.clsisv.m"
                v-model="model.clsisv.m"
                read-only
                label="[m]"
              />
            </b-col>
            <b-col cols="6">
              <cs-input
                id="model.clsisv.def"
                v-model="model.clsisv.def"
                read-only
                label="def."
              />
            </b-col>
          </b-row>
        </fieldset>
        <b-row>
          <b-col cols="12">
            <cs-input
              id="model.clsisv.ncampate"
              v-model="model.clsisv.ncampate"
              read-only
              label="Numero campate"
            />
          </b-col>
          <b-col cols="12">
            <cs-input
              id="model.clsisv.materiale"
              v-model="model.clsisv.materiale"
              read-only
              label="Materiale"
            />
          </b-col>
          <b-col cols="12">
            <cs-input
              id="model.clsisv.altrosis"
              v-model="model.clsisv.altrosis"
              read-only
              label="Presenza di ulteriori parametri di vulnerabilità sismica"
            />
          </b-col>
          <b-col cols="12">
            <cs-input
              id="model.clsisv.criteri"
              v-model="model.clsisv.criteri"
              read-only
              label="Criteri di progettazione"
            />
          </b-col>
          <b-col cols="12">
            <cs-input
              id="model.clsisv.lv"
              v-model="model.clsisv.lv"
              read-only
              label="Livello di difettosità"
            />
          </b-col>
        </b-row>

      </b-card-body>
    </b-card>
    <b-card header="Classe di Attenzione Sismica - Esposizione">
      <b-card-body>

        <b-row>
          <b-col cols="12">
            <cs-input
              id="model.clsise.strat"
              v-model="model.clsise.strat"
              read-only
              label="Strategicità dell'opera"
            />
          </b-col>
        </b-row>

      </b-card-body>
    </b-card>

    <b-card header="Classe di Attenzione al Rischio Frane - Pericolosità/Sucettibilità">
      <b-card-body>

        <b-row>
          <b-col cols="12">
            <cs-input
              id="model.clfps.riconosciuta"
              v-model="model.clfps.riconosciuta"
              read-only
              label="Frana riconosciuta (Pa)"
            />
          </b-col>
          <b-col cols="12">
            <cs-input
              id="model.clfps.potenziale"
              v-model="model.clfps.potenziale"
              read-only
              label="Frana potenziale (Pc)"
            />
          </b-col>
          <b-col cols="12">
            <cs-input
              id="model.clfps.maxv"
              v-model="model.clfps.maxv"
              read-only
              label="Massima velocità attesa in funzione della tipologia di frana in atto o potenziale (Pv)"
            />
          </b-col>
          <b-col cols="12">
            <cs-input
              id="model.clfps.magnituo"
              v-model="model.clfps.magnituo"
              read-only
              label="Magnitudo attesa su base volumetrica in metri cubi (Pm)"
            />
          </b-col>
          <b-col cols="12">
            <cs-input
              id="model.clfps.instabilita"
              v-model="model.clfps.instabilita"
              read-only
              label="Instabilità del versante"
            />
          </b-col>
          <b-col cols="12">
            <cs-input
              id="model.clfps.affidabilita"
              v-model="model.clfps.affidabilita"
              read-only
              label="Affidabilità della valutazione"
            />
          </b-col>
          <b-col cols="12">
            <cs-input
              id="model.clfps.misure"
              v-model="model.clfps.misure"
              read-only
              label="Misure di mitigazione"
            />
          </b-col>
        </b-row>

      </b-card-body>
    </b-card>
    <b-card header="Classe di Attenzione al Rischio Frane - Vulnerabilità">
      <b-card-body>

        <b-row>
          <b-col cols="12">
            <cs-input
              id="model.clfv.tipo"
              v-model="model.clfv.tipo"
              read-only
              label="Tipologia strutturale e di fondazioni"
            />
          </b-col>
          <b-col cols="12">
            <cs-input
              id="model.clfv.presenza"
              v-model="model.clfv.presenza"
              read-only
              label="Presenza di Fond. Superficiali o NON progettate per azioni orizzontali"
            />
          </b-col>
          <b-col cols="12">
            <cs-input
              id="model.clfv.estensione"
              v-model="model.clfv.estensione"
              read-only
              label="Estensione dell'interferenza"
            />
          </b-col>
        </b-row>

      </b-card-body>
    </b-card>
    <b-card header="Classe di Attenzione al Rischio Frane - Esposizione">
      <b-card-body>

        <b-row>
          <b-col cols="12">
            <cs-input
              id="model.clfe.esposizione"
              v-model="model.clfe.esposizione"
              read-only
              label="Cd Esposizione Sismica, senza merci pericolosi"
            />
          </b-col>
        </b-row>

      </b-card-body>
    </b-card>

    <b-card header="Classe di Attenzione al Rischio Idraulico - Pericolosità">
      <b-card-body>

        <fieldset class="mb-1 border cs-border p-2">
          <legend class="cs-legend">
            Fenomeno di sormonto
          </legend>
          <b-row>
            <b-col cols="6">
              <cs-input
                id="model.clip.pfp2"
                v-model="model.clip.pfp2"
                read-only
                label="Corsi d'acqua principali non arginati - Fp2"
              />
            </b-col>
            <b-col cols="6">
              <cs-input
                id="model.clip.pfp3"
                v-model="model.clip.pfp3"
                read-only
                label="Corsi d'acqua principali non arginati - Fp3"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <cs-input
                id="model.clip.sfp2"
                v-model="model.clip.sfp2"
                read-only
                label="Corsi d'acqua secondari non arginati - Fp2"
              />
            </b-col>
            <b-col cols="6">
              <cs-input
                id="model.clip.corsi"
                v-model="model.clip.corsi"
                read-only
                label="Corsi d'acqua arginati"
              />
            </b-col>
          </b-row>
        </fieldset>

        <fieldset class="mb-1 border cs-border p-2">
          <legend class="cs-legend">
            Fenomeno di erosione generalizzata
          </legend>
          <b-row>
            <b-col cols="4">
              <cs-input
                id="model.clip.wal"
                v-model="model.clip.wal"
                read-only
                label="Wa,l"
              />
            </b-col>
            <b-col cols="4">
              <cs-input
                id="model.clip.wa"
                v-model="model.clip.wa"
                read-only
                label="Wa"
              />
            </b-col>
            <b-col cols="4">
              <cs-input
                id="model.clip.ca"
                v-model="model.clip.ca"
                read-only
                label="Ca = (Wa,l / Wa)x100"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <cs-input
                id="model.clip.wgl"
                v-model="model.clip.wgl"
                read-only
                label="Wg,l"
              />
            </b-col>
            <b-col cols="4">
              <cs-input
                id="model.clip.wg"
                v-model="model.clip.wg"
                read-only
                label="Wg"
              />
            </b-col>
            <b-col cols="4">
              <cs-input
                id="model.clip.cg"
                v-model="model.clip.cg"
                read-only
                label="Cg = (Wg,l / Wg)x100"
              />
            </b-col>
          </b-row>
        </fieldset>

        <fieldset class="mb-1 border cs-border p-2">
          <legend class="cs-legend">
            Fenomeno di erosione localizzata
          </legend>
          <b-row>
            <b-col cols="4">
              <cs-input
                id="model.clip.ds"
                v-model="model.clip.ds"
                read-only
                label="ds"
              />
            </b-col>
            <b-col cols="4">
              <cs-input
                id="model.clip.df"
                v-model="model.clip.df"
                read-only
                label="df"
              />
            </b-col>
            <b-col cols="4">
              <cs-input
                id="model.clip.iel"
                v-model="model.clip.iel"
                read-only
                label="IEL = ds / df"
              />
            </b-col>
          </b-row>
        </fieldset>

      </b-card-body>
    </b-card>
    <b-card header="Classe di Attenzione al Rischio Idraulico - Vulnerabilità">
      <b-card-body>

        <b-row>
          <b-col cols="12">
            <cs-input
              id="model.cliv.sormonto"
              v-model="model.cliv.sormonto"
              read-only
              label="Sussistenza di condizioni - Fenomeno di sormonto"
            />
          </b-col>
          <b-col cols="12">
            <cs-input
              id="model.cliv.gen"
              v-model="model.cliv.gen"
              read-only
              label="Sussistenza di condizioni - Fenomeno di Erosione generalizzata"
            />
          </b-col>
          <b-col cols="12">
            <cs-input
              id="model.cliv.local"
              v-model="model.cliv.local"
              read-only
              label="Sussistenza di condizioni - Fenomeno di Erosione localizzata"
            />
          </b-col>
        </b-row>

      </b-card-body>
    </b-card>
    <b-card header="Classe di Attenzione al Rischio Idraulico - Esposizione">
      <b-card-body>
        <b-row>
          <b-col cols="12">
            <cs-input
              id="model.clie.cd"
              v-model="model.clie.cd"
              read-only
              label="Cd Esposizione Sismica, senza merci pericolosi"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-card header="Calcolo CdA Strutturale e Fondazionale">
      <b-card-body>

        <b-row>
          <b-col cols="12">
            <cs-input
              id="model.calsf.pericolo"
              v-model="model.calsf.pericolo"
              read-only
              label="Pericolosità"
            />
          </b-col>
          <b-col cols="12">
            <cs-input
              id="model.calsf.vfinale"
              v-model="model.calsf.vfinale"
              read-only
              label="Vulnerabilità"
            />
          </b-col>
          <b-col cols="12">
            <cs-input
              id="model.calsf.efinale"
              v-model="model.calsf.efinale"
              read-only
              label="Esposizione"
            />
          </b-col>
          <b-col cols="12">
            <cs-input
              id="model.calsf.cdasf"
              v-model="model.calsf.cdasf"
              read-only
              label="CdA Strutt. e Fond."
            />
          </b-col>
        </b-row>

      </b-card-body>
    </b-card>
    <b-card header="Calcolo CdA Sismica">
      <b-card-body>

        <b-row>
          <b-col cols="12">
            <cs-input
              id="model.calsis.pericolo"
              v-model="model.calsis.pericolo"
              read-only
              label="Pericolosità"
            />
          </b-col>
          <b-col cols="12">
            <cs-input
              id="model.calsis.vfinale"
              v-model="model.calsis.vfinale"
              read-only
              label="Vulnerabilità"
            />
          </b-col>
          <b-col cols="12">
            <cs-input
              id="model.calsis.efinale"
              v-model="model.calsis.efinale"
              read-only
              label="Esposizione"
            />
          </b-col>
          <b-col cols="12">
            <cs-input
              id="model.calsis.cdasis"
              v-model="model.calsis.cdasis"
              read-only
              label="CdA Sismica."
            />
          </b-col>
        </b-row>

      </b-card-body>
    </b-card>
    <b-card header="Calcolo CdA Rischio Frane">
      <b-card-body>

        <b-row>
          <b-col cols="12">
            <cs-input
              id="model.calf.pericolo"
              v-model="model.calf.pericolo"
              read-only
              label="Pericolosità / Sucettibilità"
            />
          </b-col>
          <b-col cols="12">
            <cs-input
              id="model.calf.vfinale"
              v-model="model.calf.vfinale"
              read-only
              label="Vulnerabilità"
            />
          </b-col>
          <b-col cols="12">
            <cs-input
              id="model.calf.efinale"
              v-model="model.calf.efinale"
              read-only
              label="Esposizione"
            />
          </b-col>
          <b-col cols="12">
            <cs-input
              id="model.calf.cdaf"
              v-model="model.calf.cdaf"
              read-only
              label="CdA Frane."
            />
          </b-col>
        </b-row>

      </b-card-body>
    </b-card>
    <b-card header="Calcolo CdA Rischio Idraulico">
      <b-card-body>

        <fieldset class="mb-1 border cs-border p-2">
          <legend class="cs-legend">
            Pericolosità - Fenomeno di Sormonto
          </legend>
          <b-row>
            <b-col cols="12">
              <cs-input
                id="model.cali.spprinc"
                v-model="model.cali.spprinc"
                read-only
                label="Corsi principali non arginati"
              />
            </b-col>
            <b-col cols="12">
              <cs-input
                id="model.cali.spsecon"
                v-model="model.cali.spsecon"
                read-only
                label="Corsi secondari non arginati"
              />
            </b-col>
            <b-col cols="12">
              <cs-input
                id="model.cali.spcorsi"
                v-model="model.cali.spcorsi"
                read-only
                label="Corsi arginati"
              />
            </b-col>
          </b-row>
        </fieldset>
        <fieldset class="mb-1 border cs-border p-2">
          <legend class="cs-legend">
            Pericolosità - Fenomeno di Erosione
          </legend>
          <b-row>
            <b-col cols="12">
              <cs-input
                id="model.cali.epgen"
                v-model="model.cali.epgen"
                read-only
                label="Generalizzata"
              />
            </b-col>
            <b-col cols="12">
              <cs-input
                id="model.cali.eplocal"
                v-model="model.cali.eplocal"
                read-only
                label="Localizzata"
              />
            </b-col>
          </b-row>
        </fieldset>
        <fieldset class="mb-1 border cs-border p-2">
          <legend class="cs-legend">
            Vulnerabilità
          </legend>
          <b-row>
            <b-col cols="12">
              <cs-input
                id="model.cali.vsorm"
                v-model="model.cali.vsorm"
                read-only
                label="Sormonto"
              />
            </b-col>
            <b-col cols="12">
              <cs-input
                id="model.cali.vegen"
                v-model="model.cali.vegen"
                read-only
                label="Erosione generalizzata"
              />
            </b-col>
            <b-col cols="12">
              <cs-input
                id="model.cali.velocal"
                v-model="model.cali.velocal"
                read-only
                label="Erosione localizzata"
              />
            </b-col>
          </b-row>
        </fieldset>
        <b-row>
          <b-col cols="12">
            <cs-input
              id="model.cali.efinale"
              v-model="model.cali.efinale"
              read-only
              label="Esposizione"
            />
          </b-col>
          <b-col cols="12">
            <cs-input
              id="model.cali.cdasorm"
              v-model="model.cali.cdasorm"
              read-only
              label="CdA Sormonto"
            />
          </b-col>
          <b-col cols="12">
            <cs-input
              id="model.cali.cdaegen"
              v-model="model.cali.cdaegen"
              read-only
              label="CdA Erosione generalizzata"
            />
          </b-col>
          <b-col cols="12">
            <cs-input
              id="model.cali.cdaelocal"
              v-model="model.cali.cdaelocal"
              read-only
              label="CdA Erosione localizzata"
            />
          </b-col>
          <b-col cols="12">
            <cs-input
              id="model.cali.cdae"
              v-model="model.cali.cdae"
              read-only
              label="CdA Erosione"
            />
          </b-col>
          <b-col cols="12">
            <cs-input
              id="model.cali.cdai"
              v-model="model.cali.cdai"
              read-only
              label="CdA Idraulico"
            />
          </b-col>
        </b-row>

      </b-card-body>
    </b-card>

    <b-card header="Calcolo CdA COMPLESSIVA">
      <b-card-body>

        <b-row>
          <b-col cols="12">
            <cs-input
              id="model.cal.cdaif"
              v-model="model.cal.cdaif"
              read-only
              label="CdA Idraulica e frane"
            />
          </b-col>
          <b-col cols="12">
            <cs-input
              id="model.cal.cdacomp"
              v-model="model.cal.cdacomp"
              read-only
              label="CdA COMPLESSIVA"
            />
          </b-col>
        </b-row>

      </b-card-body>
    </b-card>

  </b-overlay>
</template>

<script>

export default {
  data() {
    return {
      loading: false,
      model: {
        id: null,
        operaId: null,
        nomeOpera: null,
        nopera: null,
        iop: null,
        descrizione: null,
        prog: null,
        clas: null,
        servizio: null,
        clsfp: {
          classificazione: null,
          traffico: null,
        },
        clsfv: {
          lv: null,
          rp: null,
          norme: null,
          schema: null,
          materiale: null,
          luce: null,
          ncampate: null,
        },
        clsfe: {
          lv: null,
          luce: null,
          alternative: null,
          tipo: null,
          trasporto: null,
        },
        clsisp: {
          ag: null,
          topo: null,
          sotto: null,
        },
        clsisv: {
          schema: null,
          m: null,
          def: null,
          ncampate: null,
          materiale: null,
          altrosis: null,
          criteri: null,
          lv: null,
        },
        clsise: {
          strat: null,
        },
        clfps: {
          riconosciuta: null,
          potenziale: null,
          maxv: null,
          magnituo: null,
          instabilita: null,
          affidabilita: null,
          misure: null,
        },
        clfv: {
          tipo: null,
          presenza: null,
          estensione: null,
        },
        clfe: {
          esposizione: null,
        },
        clip: {
          pfp2: null,
          pfp3: null,
          sfp2: null,
          corsi: null,
          wal: null,
          wa: null,
          ca: null,
          wgl: null,
          wg: null,
          cg: null,
          ds: null,
          df: null,
          iel: null,
        },
        cliv: {
          sormonto: null,
          gen: null,
          local: null,
        },
        clie: {
          cd: null,
        },
        calsf: {
          pericolo: null,
          vfinale: null,
          efinale: null,
          cdasf: null,
        },
        calsis: {
          pericolo: null,
          vfinale: null,
          efinale: null,
          cdasis: null,
        },
        calf: {
          pericolo: null,
          vfinale: null,
          efinale: null,
          cdaf: null,
        },
        cali: {
          spprinc: null,
          spsecon: null,
          spcorsi: null,
          epgen: null,
          eplocal: null,
          vsorm: null,
          vegen: null,
          velocal: null,
          efinale: null,
          cdasorm: null,
          cdaegen: null,
          cdaelocal: null,
          cdae: null,
          cdai: null,
        },
        cal: {
          cdaif: null,
          cdacomp: null,
        },
      },
    }
  },
  created() {
    if (!this.$grants.LIVELLO2_VISUALIZZA_DETTAGLIO.can) this.$routing.ERRORE401.push()

    const { id } = this.$route.params

    this.loading = true
    this.$remote.livello.single_byOpera(id)
      .then(res => {
        this.setBreadcrumbData(this.$store, this.$route.name, { id, nome: res.data.nomeOpera })
        this.model = res.data
        this.loading = false
      })
      .catch(res => {
        this.showReposonseMessage(res)
        this.$routing.ERRORE404.push()
      })
      .finally(() => { this.loading = false })
  },
  methods: {
    downloadPdf() {
      this.loading = true

      this.$remote.livello.pdf_byOpera(this.model.operaId)
        .then(res => {
          const nome = `Livello 2 ${this.model.codiceOperaGlobale}.pdf`
          this.downloadFile(res.data, nome)
        })
        .catch(res => this.showReposonseMessage(res))
        .finally(() => { this.loading = false })
    },
  },
}
</script>
